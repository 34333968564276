import Notes from './Notes/Notes'

const Home = ({ showAlert }) => {
  return (
    <>
      <Notes showAlert={showAlert} />
    </>
  )
}

export default Home
